<template>
    <div class="equipment-create">
        <custom-input :record="equipment" v-on:update="equipment['vin'] = $event.target.value" prop="vin" label="VIN" />
    </div>
</template>

<script>
import CustomInput from '../../components/inputs/CustomInput.vue'
import EquipmentModel from '../../models/EquipmentModel'
 
    export default {
        components: {
            CustomInput
        },
        computed: {
        },
        data: function () {
            return {
                equipment: new EquipmentModel(),
                fields: [
                    'make', 
                    'trailerNumber', 
                    'year', 
                    'nominalLength',
                    'description',
                    'vin',
                    'doorType',
                    'suspensionType',
                    'wallType',
                    'gpsEquipped',
                    'registrationState',
                    'plateNumber',
                    
                ]
            }
        }
    }
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
</style>