<template>
    <div class="custom-input form-group">
        <template v-if="(record.typeof[prop] instanceof Enum)">
            <label v-for="option, i in record.typeof[prop]" :key="i">
                <input type="radio" v-model="value" :value="i" /><span>{{option}}</span>
            </label>
        </template>
        <template v-else>
            <label>{{label}}<i class="danger" v-if="!record.nullable[prop]">*</i>
                <input type="text" :class="{'input-error': record._valid[prop]}" :value="record[prop]" @input="input" :maxlength="maxlength" @blur="blur" />
            </label>
        </template>
        <span class="error-message" v-if="blurred">{{record._error[prop]}}</span>
    </div>
</template>

<script>
import Enum from '../../models/Enum';


export default {
    props: [
        'modelValue',
        'label',
        'prop',
        'record',
        'placeholder',
        'options'
    ],
    data: function(){
        return {
            Enum,
            blurred: false,
        }
    },
    emits: [
        'update:modelValue'
    ],
    methods: {
        input($event){
            this.$emit("update", $event.target.value);
            // this.modelValue[this.prop] = ;
        }, 
        blur(){
            this.blurred = true;
        }

    },
    computed: {
        value: {
            set (value){
                this.$emit('update:modelValue', value);
            },
            get (){
                return this.record.valueof[this.prop] //this.modelValue; 
            }
        },
        maxlength(){
            if (Object.hasOwnProperty.call(this.record.__validation, this.prop)){
                if (Object.hasOwnProperty.call(this.record.__validation[this.prop], 'length')){
                    return this.record.__validation[this.prop].length;
                }
                if (Object.hasOwnProperty.call(this.record.__validation[this.prop], 'maxlength')){
                    return this.record.__validation[this.prop].maxlength;   
                }
            }
            return false;
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";

</style>
